<template>
  <lock-scroll :locked="lockScroll">
    <lock-screen :locked="lockScreen" />
    <app-layout>
      <div
        class="flex flex-col items-center pb-32"
        v-loading.fullscreen.lock="loading">
        <div class="w-full px-6 pt-6 2xl:w-3/5 2xl:px-0">
          <div class="flex flex-col md:flex-row py-4">
            <div class="flex flex-row items-center">
              <sa-icon-button
                with-bg
                iconClass="bx bx-left-arrow-alt"
                @click="$router.push({ name: 'orders.index' })" />
              <span class="mx-2 text-lg font-bold">
                Orden de servicio
              </span>
            </div>
            <div class="flex flex-row items-center ml-auto">
              <div class="whitespace-nowrap">
                <el-button @click="whatsapp" class="el-button--success" :class="{ 'p-2' : isMobile }" v-if="id">
                  <span v-if="!isMobile">WhatsApp</span>
                  <i v-if="isMobile" class='text-md bx bxl-whatsapp'></i>
                </el-button>
                <el-button @click="notes" class="el-button--info" :class="{ 'p-2' : isMobile }" v-if="id">
                  <span v-if="!isMobile">Notas</span>
                  <i v-if="isMobile" class='text-md bx bx-note'></i>
                </el-button>
                <el-dropdown v-if="id && !isMobile" split-button type="warning" class="mx-2" @command="print">
                  <span>Imprimir</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="default">Carta</el-dropdown-item>
                    <el-dropdown-item command="thermal">Térmico</el-dropdown-item>
                    <el-dropdown-item command="sticker">Sticker</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown v-if="id && isMobile" class="mx-2" trigger="click" @command="print">
                <span class="el-button el-button--warning p-2">
                  <i class="text-md bx bx-printer"></i>
                  <i class="text-md el-icon-arrow-down el-icon--right"></i>
                </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="default">Carta</el-dropdown-item>
                    <el-dropdown-item command="thermal">Térmico</el-dropdown-item>
                    <el-dropdown-item command="sticker">Sticker</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <quick-start-tip
                  title="Guarda tus cambios"
                  content="Cuando guardas una orden se manda un correo en automatico al cliente.
                  NO OLVIDE GUARDAR SUS CAMBIOS."
                  placement="bottom"
                  :bg-purple="false"
                  :value="showQuickStartTip"
                  @onNextStep="handleNextStep">
                  <div class="inline-block" :class="{ 'quick-start-selected': $store.state.quickStart.step === 8 }">
                    <el-button
                      :disabled="id && !$store.state.account.canEditOrders || !id && !$store.state.account.canCreateOrders"
                      @click="submit"
                      class="el-button--primary"
                      :class="{ 'p-2' : isMobile }">
                      <span v-if="!isMobile">Guardar</span>
                      <i v-if="isMobile" class='text-md bx bx-save'></i>
                    </el-button>
                  </div>
                </quick-start-tip>
                <el-dropdown
                  v-if="id && !isMobile"
                  split-button
                  type="danger"
                  class="mx-2"
                  @command="close"
                  :disabled="!$store.state.account.canEditOrders">
                  <span>Cerrar</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="restrictedStatuses.Finalized">
                      {{ restrictedStatuses.Finalized.name }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="protectedStatuses.Reject.isActive"
                      :command="protectedStatuses.Reject">
                      {{ protectedStatuses.Reject.name }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="protectedStatuses.NoSolution.isActive"
                      :command="protectedStatuses.NoSolution">
                      {{  protectedStatuses.NoSolution.name }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="protectedStatuses.Warranty.isActive"
                      :command="protectedStatuses.Warranty">
                      {{ protectedStatuses.Warranty.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  v-if="id && isMobile"
                  class="mx-2"
                  trigger="click"
                  @command="close"
                  :disabled="!$store.state.account.canEditOrders">
                  <span class="el-button el-button--danger p-2">
                    <i class="text-md bx bx-x"></i>
                    <i class="text-md el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="restrictedStatuses.Finalized">
                      {{ restrictedStatuses.Finalized.name }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="protectedStatuses.Reject"
                      :command="protectedStatuses.Reject">
                      {{ protectedStatuses.Reject.name }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="protectedStatuses.NoSolution.isActive"
                      :command="protectedStatuses.NoSolution">
                      {{ protectedStatuses.NoSolution.name }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="protectedStatuses.Warranty.isActive"
                      :command="protectedStatuses.Warranty">
                      {{ protectedStatuses.Warranty.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center">
              <span class="mx-2 text-lg font-bold">
                {{ this.$state.order.reference }}
              </span>
          </div>
          <div
            v-if="errors.length > 0"
            class="px-4 text-red-500">
            <ul class="p-2 list-disc">
              <li v-for="error in errors" :key="error.field">{{ error.message }}</li>
            </ul>
          </div>
          <div class="lg:flex xl:flex lg:flex-row xl:flex-row md:space-x-2 lg:space-x-2 xl:space-x-2">
            <div class="flex-col sm:w-full md:w-2/3 lg:w-2/3 xl:w-2/3">
              <el-tabs v-model="activeTab">
                <el-tab-pane name="repairs">
                  <span slot="label">
                    <div class="px-2 space-x-1">
                      <span>Reparaciones</span>
                      <span class="px-1 py-0.5 text-gray-500 bg-gray-200 rounded-full text-sm font-bold whitespace-pre-wrap">
                        {{ orderDevicesCountDescription }}
                      </span>
                    </div>
                  </span>
                  <devices-list :active-device="activeDevice" />
                </el-tab-pane>
                <el-tab-pane name="sales">
                  <span slot="label">
                    <div class="px-2 space-x-1">
                      <span>Ventas</span>
                      <span class="px-1 py-0.5 text-gray-500 bg-gray-200 rounded-full text-sm font-bold whitespace-pre-wrap">
                        {{ orderProductsCountDescription }}
                      </span>
                    </div>
                  </span>
                  <products-list />
                </el-tab-pane>
                <el-tab-pane name="history">
                  <span slot="label">
                    <div class="px-2 space-x-1">
                      <span>Historial</span>
                    </div>
                  </span>
                  <history />
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="flex-col sm:w-full md:w-2/3 lg:w-1/3 xl:w-1/3 pt-12 mt-1.5 space-y-4">
              <client-block />
              <payment-block />
              <details-block />
              <images-block />
            </div>
          </div>
        </div>
      </div>
    </app-layout>
  </lock-scroll>
</template>
<style scoped>
</style>
<script>
import _ from "lodash";
import { state, actions, getters, mutations, mappers } from './store';
import OrderService from '@/services/OrderService';
import ClientService from '@/services/ClientService';
import OrderNotesManager from '@/components/dialogs/OrderNotesManager/service';
import { tag } from '@/constants';
import { Whatsapp, getCountryCode } from '@/utils/phones';
import responsiveSize from '@/mixins/responsiveSize';
import { QUICK_START_STORE } from '@/store/modules/quick-start/mutation-types';
import OrderPaymentDialogService, {
  command as OrderPaymentDialogCommand,
  commands as OrderPaymentDialogCommands,
} from '@/components/dialogs/OrderPayment/service';
import {
  getRestrictedStatuses,
  getProtectedStatuses,
} from '@/helpers/order-status.helper';
import NotificationService from '@/services/NotificationService';


export default {
  name: 'OrdersFormIndex',
  mixins: [responsiveSize],
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout.vue'),
    DevicesList: () => import('./device/DevicesList.vue'),
    ProductsList: () => import('./product/ProductsList.vue'),
    ClientBlock: () => import('./client/ClientBlock.vue'),
    DetailsBlock: () => import('./detail/DetailsBlock.vue'),
    PaymentBlock: () => import('./payment/PaymentBlock.vue'),
    ImagesBlock: () => import('./images/ImagesBlock.vue'),
    History: () => import('./history/History.vue'),
  },
  created() {
    window.addEventListener("resize", this.resize);
    mutations.resetState();
    this.$state = state;
    this.resize();
    this.subscription = OrderPaymentDialogCommand.subscribe(this.handleOrderPaymentDialogCommands);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
    this.subscription.unsubscribe();
  },
  data() {
    return {
      id: undefined,
      activeTab: 'repairs',
      errors: [],
      isMobile: false,
      lockScreen: false,
      lockScroll: false,
      activeDevice: null,
      showQuickStartTip: false,
      subscription: null,
      loading: false,
    };
  },
  watch: {
    '$store.state.quickStart.step'(step) {
      if (step === 8) this.showQuickStartTip = true;
    }
  },
  computed: {
    orderDevicesCountDescription() {
      return getters.orderDevicesCount();
    },
    orderProductsCountDescription() {
      return getters.orderProductsCount();
    },
    restrictedStatuses() {
      const {
        Finalized
      } = getRestrictedStatuses(this.$store.state.catalogs.order.statuses);

      return {
        Finalized
      };
    },
    protectedStatuses() {
      const {
        Reject,
        NoSolution,
        Warranty
      } = getProtectedStatuses(this.$store.state.catalogs.order.statuses);

      return {
        Reject,
        NoSolution,
        Warranty
      };
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.lockScreen = this.$route.params.lockScreen || false;
    if (this.id) {
      this.getOrder(this.$route.params.id);
    } else {
      this.setOrderTaxes();
    }
    if (this.$route.params.clientId) {
      this.loadClient(this.$route.params.clientId);
    }
  },
  methods: {
    async notes() {
      OrderNotesManager.show(this.id);
    },
    async print(command) {
      if (!command) return;
      await actions.validateOrder();
      const error = state.order.orderDevices.filter((orderDevice) => !orderDevice.deviceModelId);
      if (error.length) {
        this.$toastr.e('Error al capturar dispositivo');
        return;
      }
      const request = mappers.mapPostRequest();
      await OrderService.update({ ...request, id: this.id });
      await NotificationService.get({ businessInfoId: this.$store.state.business.id });
      await this.$router.push({ name: `invoices.${command}`, params: { order: { ...this.$state.order, id: this.id } } });
    },
    async submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        await actions.validateOrder();
        const error = state.order.orderDevices.length
          && state.order.orderDevices.filter((orderDevice) => !orderDevice.deviceModelId);
        if (error.length) {
          this.$toastr.e('Error al capturar dispositivo');
          return;
        }
        const request = mappers.mapPostRequest(this.$store.state.business.id);
        if (this.id) {
          await OrderService.update({ ...request, id: this.id });
          await NotificationService.get({ businessInfoId: this.$store.state.business.id });
          await this.$router.push({ name: 'invoices.default', params: { id: this.id } });
          await this.$toastr.s('Se actualizo con exito');
        } else {
          const { id } = await OrderService.create(request);
          await NotificationService.get({ businessInfoId: this.$store.state.business.id });
          const isSale = request.orderDevices.length === 0 && request.orderProducts.length > 0;
          if (!isSale) {
            await this.createSuccess(id);
          } else {
            const { Finalized } = this.restrictedStatuses;
            OrderPaymentDialogService.show({ orderId: id, status: Finalized.id, isNew: true });
          }
        }
      } catch(error) {
        this.errors = this.$state.errors;
      } finally {
        this.loading = false;
      }
    },
    async createSuccess(id) {
      await this.$router.push({ name: 'invoices.default', params: { id } });
      await this.$toastr.s('Se creo con exito');
    },
    async whatsapp() {
      this.loading = true;
      try {
        const countryCode = getCountryCode(
          this.$store.state.catalogs.flags,
          this.$state.client.phoneCountryCodeId,
        );
        if (!countryCode) {
          this.$toastr.e('El Cliente debe tener registrado el Código de Area');
          return;
        }
        const pdf = await OrderService.getPdfByOrderId({ orderId: this.id, businessInfoId: this.$store.state.business.id });
        Whatsapp({
          code: countryCode,
          phone: this.$state.client.phone,
          text: `Estimado/a ${this.$state.client.fullName}; Gracias por ponerse en contacto con nosotros. Su ticket de servicio se puede ver, imprimir o descargar como PDF mediante el enlace siguiente. ${tag.br}${tag.br}${pdf}`,
        });
        await OrderService.addHistoryWhatsapp({
          orderId: this.id,
          message: `Estimado/a ${this.$state.client.fullName}; Gracias por ponerse en contacto con nosotros. Su ticket de servicio se puede ver, imprimir o descargar como PDF mediante el enlace siguiente. ${pdf}`
        })
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }

    },
    async close(command) {
      if (!command) return;
      switch (command) {
        case this.restrictedStatuses.Finalized:
          OrderPaymentDialogService.show({ orderId: this.id, status: command.id });
          break;
        case this.protectedStatuses.Reject:
          if (!this.protectedStatuses.Reject.isActive) {
            // premature break because Reject status should not be displayed due is inactive
            break;
          }
          await OrderService.updateStatus({ id: this.id, status: command.id });
          await this.$router.push({ name: 'invoices.default', params: { id: this.id } });
          break;
        case this.protectedStatuses.NoSolution:
          if (!this.protectedStatuses.NoSolution.isActive) {
            // premature break because NoSolution status should not be displayed due is inactive
            break;
          }
          await OrderService.updateStatus({ id: this.id, status: command.id });
          await this.$router.push({ name: 'invoices.default', params: { id: this.id } });
          break;
        case this.protectedStatuses.Warranty:
          if (!this.protectedStatuses.Warranty.isActive) {
            // premature break because Warranty status should not be displayed due is inactive
            break;
          }
          await OrderService.applyWarranty(this.id);
          await this.$router.push({ name: 'invoices.default', params: { id: this.id } })
          break;
        default:
      }
    },
    async getOrder(orderId) {
      const result = await OrderService.getById(orderId);
      const order = mappers.mapGetResponse(result);
      this.$state.order = order;
      this.$state.client = order.client;
      this.activeDevice = order.orderDevices.length ? 0 : null;
    },
    async loadClient(clientId) {
      this.$state.order.clientId = clientId;
      this.$state.client = await ClientService.getbyId(clientId);
    },
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
    handleNextStep() {
      const step = this.$store.state.quickStart.step;
      this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
      if (step === 8) {
        this.showQuickStartTip = false;
        this.$router.push({ name: 'invoices.default', params: { id: this.id, lockScroll: true, lockScreen: true } })
      }
    },
    async handleOrderPaymentDialogCommands({ type, payload }) {
      switch (type) {
        case OrderPaymentDialogCommands.Select:
          await OrderService.updateStatus({
            id: payload.orderId,
            status: payload.status,
            paymentMethod: payload.paymentMethod,
            endDate: payload.endDate
          });
          if (payload.isNew) {
            await this.createSuccess(payload.orderId);
          } else {
            await this.$router.push({ name: 'invoices.default', params: { id: payload.orderId } });
          }
          break;
        default:
      }
    },
    setOrderTaxes() {
      const { businessTaxes } = this.$store.state.catalogs;

      if (!_.isEmpty(businessTaxes)) {
        const firstBusinessTax = businessTaxes[0];
        mutations.changeTax({
          taxRate: firstBusinessTax.percentage,
        });
        mutations.changeApplicableTaxes([{ id: firstBusinessTax.id }]);
      }
    },
  },
};
</script>
